import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/visions.module.css'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c4" }) {
      headline
      byline
      ariel1 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      ariel2 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      cinderella {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      js {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      wedding {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      beach {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      cruiser {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      red {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const Visions = () => {
  const { content } = useStaticQuery(essayQuery)
  return (
    <Layout>
      <section>
        <h1
          className='headline'
          dangerouslySetInnerHTML={{ __html: content.headline }}
        />
        <p
          dangerouslySetInnerHTML={{ __html: content.byline }}
        />
            <p>
              The ideas for Seaside were germinated and planted in{' '}
              <q>interesting times</q>. In 1946, when my grandfather, J.
              Smolian, <q>J.S.</q>, bought 80 acres next to Seagrove Beach, it
              was to make a summer camp for the employees of Pizitz, the
              department store he had built with his father-in-law, Louis
              Pizitz, <q>Big Papa</q>. They shared a lifetime of similar
              experiences including Cossacks riding through their town in
              Western Russia (Eastern Poland) and of different outlooks though
              both were sons of Rabbis. J.S. was not an observant Jew, but he
              was, in his way, like a Rabbi, a man other intelligent people
              would seek out for his wisdom and his all-too-uncommon{' '}
              <q>common sense</q>.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.ariel1.childImageSharp]}
              />
              Aerial view of Seaside 1965
            </div>
            <p>
              Big Papa was a merchant. He knew his <q>schmatte</q> and he knew
              how to sell. He was tall and handsome, and was a ladies man until
              the end of his long life. On the third floor of Pizitz in the
              Ladies Ready-to- Wear department, he would hold court in his
              throne-like chair from which he could survey the scene. At the
              edges of an impressive expanse of carpet in front of his throne
              were racks of women&apos;s clothing, and women looking at the
              clothing. If one of the women appealed to Big Papa, he would
              saunter over to help her with her choice. Though he was
              adventurous as a ladies man, he was very cautious as a
              businessman, and he and J.S., who was constantly dreaming of a
              bigger and better business, were often at odds.
            </p>
            <p>
              J.S., a visionary, felt frustrated by Big Papa&apos;s conservatism
              and resistance to change. As a visionary, like Bobby Kennedy, J.S,
              <q>saw things as they could be and asked &apos;why not&apos;?</q>
            </p>
            <p>
              The coffee shop was my favorite place in the store. (Since we
              moved from Birmingham when I was nine, I was too young at the time
              to appreciate Big Papa&apos;s place of pulchritude as much as I
              now enjoy Cinderella Circle in Seaside.) I loved the hot fudge
              sundaes and banana splits. Big Papa loved taking my sister Laurie
              and me there and watching us gorge ourselves on both. But when the
              coffee shop first was installed on the mezzanine, Big Papa had a
              conniption. J.S. had stayed up all night overseeing its sudden and
              secret installation. He slept late the next morning; by the time
              he got to work, Big Papa had demolished much of it, lest it{' '}
              <q>stink up my schmatte</q> as he bellowed.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.js.childImageSharp]}
              />
              J.S. and young Robert
            </div>
            <p>
              But JS had it rebuilt, and once it was there and people liked it,
              it became Big Poppa&apos;s favorite place and he proudly accepted
              compliments on what a great idea his coffee shop was. The growth
              of the store had followed this pattern for decades, but by the
              late ‘40&apos;s, Big Papa, in his eighties, had given up worrying
              about the day-to-day, so that he could hold court with his ladies
              and smoke cigars and play pinochle with his cronies.
            </p>
            <p>
              So in 1946, when J.S. germinated the idea of a summer camp for
              Pizitz employees (similar to Camp Helen, which Avondale Mills
              built for its employees) it was Big Papa&apos;s son, Isidore
              Pizitz,
              <q>Bud</q>, who scoffed at the idea as being too progressive and
              too expensive. J.S. was pleased with the purchase of 80 acres and
              a half-mile of beach,{' '}
              <q>
                and 40 acres underwater that might be worth something someday
              </q>
              . It was only a few miles from Camp Helen, and a Pizitz summer
              camp would be great for building morale. <q>Team building</q> had
              been one of Big Poppa and J.S.&apos;s greatest strengths together,
              though at that time they would not have used the term. Through the
              Depression, they had shown their loyalty to customers and
              employees alike, by extending credit, developing a <q>layaway</q>
              program, and by having big dinners for all their fellow citizens
              who could not afford a Thanksgiving or Christmas feast.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.beach.childImageSharp]}
              />
              Robert and J.S. at the beach 1946
            </div>
            <div>
              <p>
                The generous gesture of sharing the land with his partners and
                sharing with his employees the experience of going to the beach
                would have been adopted by Big Papa, after some initial
                resistance, but Bud wouldn&apos;t budge.
                <sup>
                  <a id='fnref:1' href='#fn:1'>
                    1
                  </a>
                </sup>{' '}
                J.S. put the deed in a safe deposit box, and tried to let go of
                his original idea.
              </p>
              <p>
                He didn&apos;t forget his land purchase, however. He had the
                property cleared with a bulldozer so that each summer we would
                see its full extent when we would drive out in en famille. J.S.
                would show us the place that, in his vision, would one day be a
                great human settlement. Later, he and my grandmother gave a
                parcel of Seaside to the University of Alabama in Birmingham to
                build a small conference center and housing for faculty and
                visiting scholars.
                <sup>
                  <a id='fnref:2' href='#fn:2'>
                    2
                  </a>
                </sup>{' '}
              </p>
              <p>
                In the mid 1940&apos;s, people&apos;s memory of the Depression
                was strong and so was the fear that it could return. Most
                thought that the artificial prosperity of the war would come to
                an end. Few could see that the USA would maintain a wartime
                military-industrial complex indefinitely or build a brave new
                world which would provide perpetual prosperity to autoworkers
                and road builders and homebuilders. Recently, however, with oil
                beginning to run out and the value of houses beginning to
                correlate inversely with the cost of getting to them, we seem to
                living in
                <q>interesting times</q> again.
              </p>
              <p>
                By the late 1970&apos;s, when the planning for Seaside began, it
                seemed that our oil-dependent economy would require behavioral
                adjustments if we were to avoid spending the rest of our lives
                waiting in line for gasoline. Seaside&apos;s groundbreaking in
                1981 came right after the demise of John Anderson&apos;s
                Republican primary bid and, along with it, his idea to raise
                fuel taxes to move us toward energy independence. Reagan won the
                presidential race by announcing that it was{' '}
                <q>morning in America</q>. The Reagan era and Seaside began in
                the midst of a national real estate recession. To me, it seemed
                like a foggy and overcast morning.
              </p>
              <p>
                At any rate in 1981, an apparently inauspicious year to start an
                ambitious real estate endeavor, Seaside set out on a path that
                combined a conservative business plan and a progressive, perhaps
                even radical, social plan. Seaside would limit leverage, since
                my previous project in Miami had almost gone under in the
                mid-seventies downturn that had featured double-digit interest
                rates. Seaside would thus grow slowly, one street at a time. But
                Seaside would try to address some of the social and economic
                issues that, by 1981, coalesced into a crisis of confidence in
                our culture.
              </p>

              <p>
                Seaside was designed for compact cars and clotheslines. It was
                assumed that air conditioning would be expensive and politically
                incorrect. Porch sitting during the early evening, waiting for
                the cottage to cool, would become a revered ritual as well as an
                appropriate adaptation to the climate.
              </p>

              <p>
                Seaside&apos;s cottages, we thought, would be modest with tiny
                bedrooms but expansive porches and with outdoor kitchens and
                outdoor showers to avoid heating the house. But even so, we
                assumed that these houses, at $50,000 - $150,000, would be
                beyond the reach of the teachers, artists and artisans who would
                have places in our town. So the Seaside Urban Code allowed small
                airstreams and screen porches to satisfy the building
                requirement. The rest of the property could be built out slowly,
                as time and resources allowed.
              </p>

              <p>
                The Code envisioned large houses along some streets, mainly
                because every small town needed an Elm Street, like
                Birmingham&apos;s Highland Avenue, built in the early 1900s. By
                the 1950&apos;s, Big Poppa had moved to the suburbs, and his
                mansion on Highland Avenue, like most of the rest of the grand
                houses, had become a boarding house. Boarding houses or
                bed-and-breakfast inns would make sense in a holiday town and
                would provide entrepreneurial opportunities for people who
                wanted, or needed, to work for themselves.
              </p>
              <p>
                Restraint, accommodating to the constraints of scarce oil and
                diminished economic expectations, would fit Seaside for a new
                era. Getting back to the basics could revitalize the idealism we
                had shared in the 1960&apos;s and the sense of community we had
                experienced in the 1950&apos;s.
              </p>
              <p>
                By the end of the 1970&apos;s, our culture&apos;s retreat from
                the sixties and from the city was well underway; people had fled
                to the suburbs, to enclaves of people like themselves, so that
                their children could go to <q>good</q> schools and play in{' '}
                <q>safe</q>
                neighborhoods. A generation was growing up without{' '}
                <q>street smarts</q>. (<q>Cul-de-sac smarts</q> never amounted
                to much.) Their early years were spent in straitjackets, in the
                back seat of cars, experiencing the world as a moving picture,
                through a window about the size of a large TV.
              </p>
              <p>
                The most radical (and as it turned out the most appealing) idea
                of Seaside was to liberate these children and to encourage them
                to explore the world via Seaside&apos;s narrow streets and
                narrower footpaths. They could find secret gardens along the
                way, or make forts from construction materials. They could also
                run a charge at Modica Market and thus runs errands for the
                family. One little girl was sent on twelve separate trips from
                her family&apos;s cottage to collect a dozen eggs.
              </p>
              <p>
                Modica Market was a haven for lost children. I knew I&apos;d
                find my son, Micah, there when he wandered off. When he was a
                young teenager, he was one of the dozens of kids whose first
                work experience was bagging groceries under Charlie and Charles,
                Jr.&apos;s watchful eyes. Still later, as a St. John&apos;s
                College student on holiday, he worked at Sundog Books,
                recommending Great Books to anyone who inquired.
              </p>
              <p>
                Seaside&apos;s shops and restaurants were gathering places. They
                were the <q>third places</q> of Ray Oldenburg&apos;s The Great
                Good Place, a book which celebrated the few remaining taverns,
                barber shops, corner stores and cafes that remained in a
                sub-urbanizing society and described their disappearance as an
                American tragedy. The loss of <q>third</q> places -open to the
                public and likely to lead to chance encounters with friends,
                neighbors and casual acquaintances- was a blow to public life
                and to civil society. A society segregated by interest, attitude
                and demographics into narrow slices would forget the most
                important civilizing role of cities, the practice of getting
                along with strangers and with people we know who are not like
                us.
              </p>
              <p>
                It was Seaside&apos;s goal to liberate people of all ages from
                imprisonment in cocoons of supposed safety -home, car and work-
                and to join the rest of the human race as pedestrians. As it has
                turned out, in today&apos;s America even work has ceased to be a
                haven of safety, since so much of it has been outsourced to
                people in other places happy to do more for less, and as
                building and selling each other houses and financial instruments
                ceased to be a sustainable foundation for an economy when we
                realized we could not continue to borrow against those houses to
                consume our way to prosperity.
              </p>
            </div>
            <p>
              Cars of course have always been dangerous places to spend time,
              and as we spent more and more time in them, we were offered
              distractions like cell phones and text messaging and food meant to
              be eaten while driving. Because we were so dependent on driving
              for all our daily needs, we ignored the facts. In one of our many
              cases of denial bordering on delusion, we accepted as{' '}
              <q>normal</q>
              that 40,000 Americans would die each year in traffic{' '}
              <q>accidents</q>, one third of them teenagers, children for whose
              sake we rationalized our choice to live a <q>safe</q> sub-urban
              life. If this many children were being killed by terrorists or in
              battle, we would be marching in protest.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.cruiser.childImageSharp]}
              />
              Robert, Daryl &amp; Land Crusier
            </div>
            <div>
              <p>
                Home, however, was a refuge, a store of value for savings and a
                place to share values with children. But time with them was
                scarce, with both parents working harder to support a
                debt-burdened <q>lifestyle</q>, and both parents commuting
                longer,
                <q>driving for value</q>, for the biggest house with payments
                that could be covered by two incomes.
              </p>
              <p>
                But on holiday at the beach, long uninterrupted days with
                families would allow us to know our parents, grandparents,
                aunts, uncles, cousins and close friends in a different way. At
                the beach, people are more relaxed, and the distance between
                adults and children is lessened, as adults become more childlike
                and playful and children experience independence in new ways.
              </p>
              <p>
                Storytelling is a favored pastime in Southern culture, and my
                family was good at it. My grandfather and great grandfather were
                outgoing and garrulous sons of Rabbis, whose day jobs had been
                telling stories and drawing lessons from them. My mother and
                grandmother had both studied drama at Radcliffe. Though they did
                not become professional actresses, my grandmother had a radio
                show for years, and my mother ran a children&apos;s theater
                troupe, The Lakeshore Players. So much of what I know about my
                family comes from sitting up late at night, on the porch of the
                beach cottage, listening to the telling and retelling of
                favorite anecdotes from our family&apos;s adventures. With each
                retelling, embellishments would be added, and the story would
                gain in richness if not necessarily in accuracy or
                verisimilitude.
              </p>
              <p>
                Because storytelling was core to the experience we hoped Seaside
                would foster for families on holiday, Daryl and I resisted
                allowing televisions in rental cottages. It seemed simple enough
                before <q>home theaters</q> to ask the owners of those cottages
                to lock the TVs in the owners&apos; closet. Our early sales
                brochures and advertisements had emphasized the idea of
                disconnecting from all of the busyness of quotidian existence,
                including the hyper-connectedness that was beginning to pervade
                our lives, in order to re-connect with family and friends and
                with ourselves.
                <q>The Luxury of Simplicity,</q> the title of one of the essays
                in the first brochure, was imagined as white cotton curtains
                blowing in open windows and friends and family spending extended
                evenings sharing time with each other and passing on tales and
                traditions to children and grandchildren.
              </p>
              <p>
                Jacky Barker, Seaside&apos;s sales manager, agreed to the
                request of a few early Seaside cottage owners to rent their
                places to vacationers and she persuaded us that TVs were so
                central to the life of American families that no one would rent
                the cottages without them. She was probably right, and in any
                case PDAs and portable game consoles would soon take
                hyper-connectedness to a new level that would make the absence
                of TVs in rental cottages a futile gesture. But Daryl and I had
                loved the isolation and peace of our early years of planning and
                planting Seaside while living in a cottage in Grayton Beach with
                no phone, TV or air conditioning. We had learned the art of
                aestivation - spending the summer in a state of torpor and
                moisture- in Miami, where our Apogee townhouse always had open
                windows, slowly moving ceiling fans and outdoor showers. I had
                built Apogee in the mid-seventies; it had two bedrooms, but no
                interior walls or doors, so it ventilated very well. It also
                sold very slowly, as a result of its unconventional and
                modernist design as well as the prevailing rates for home loans
                of 18%.
              </p>
              <p>
                Later, when asked what I would do differently if I could start
                Seaside over, I would often muse about how the absence of TVs
                might have affected the type of people who came to Seaside.
                Recently, a friend and fellow developer suggested that I should
                have followed the example of Oglethorpe who founded Georgia with
                a ban on slavery, liquor and lawyers. It was too good an idea to
                last, however. As soon as Oglethorpe returned to England, a
                group of lawyers came over the border from Carolina, got
                everyone drunk and persuaded the intoxicated citizens to
                eliminate the ban on slavery. So, even though I might muse now
                about how much better Seaside might be without TVs or lawyers,
                it is hard to imagine such social engineering succeeding.
              </p>
              <p>
                Seaside&apos;s social engineering would focus mainly on
                liberating those without drivers license from dependency on
                those with them, on enticing people into the public realm by
                making strolling more pleasant and convenient than driving and
                making numerous places where spending time with neighbors was
                more pleasant than staying home. Downtown Seaside&apos;s cafes
                and restaurants were among these places, and they proved popular
                with residents and visitors alike. The welcome people felt in
                Modica Market and in Bud and Alley&apos;s restaurant persuaded
                more than a few people to purchase property in Seaside.
              </p>
            </div>
            <p>
              But Seaside also had parks, plazas and pavilions, not just the
              formless <q>open space</q> of most recent developments. Daryl and
              I were married at the Tupelo Street Gazebo, along with Bud and
              Guiness, our dachshunds. The gazebo later served as a magnet for
              generations of children whose families would gather around the
              edges of Tupelo Circle watching tricycle races around the circle
              and king of the fort games in the gazebo. The amphitheater in
              Seaside&apos;s Central Square, likewise served as a frisbee field
              as well as a place to sit for concerts and movies. And the Seaside
              Post Office may be the most photographed spot in Seaside, and Pat
              Day, its Postmistress, one of the town&apos;s best known citizens.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.wedding.childImageSharp]}
              />
              Robert &amp; Daryl on their wedding day with Bud
            </div>
            <div>
              <p>
                The pavilions terminating each of Seaside&apos;s north-south
                streets are probably Seaside&apos;s most distinctive public
                places. Functioning as communal stairs to the beach and porches
                overlooking the sea, they have allowed Seaside to persuade
                people that living a block or two from the beach is perhaps
                preferable to living right on the top of the dune. Walking down
                a street lined with porches, pausing for conversations with
                neighbors and seeing the sea framed by a beautiful ceremonial
                gateway celebrates the procession to the sea in a way that a
                trip in an elevator never can. The view of the sea through the
                picture window or sliding doors of a condo is too much like an
                image on a TV to make living in such a place preferable to
                living in a Seaside cottage, at least for the admittedly small
                number of people who chose Seaside.
              </p>
              <p>
                Slowing down in Seaside also meant that sales and construction
                were slow, at first because it was a hard sell for most
                prospective purchasers to pay as much for a cottage a block from
                the beach as for a beachfront condo. But even after sales began
                to take off, we made the deliberate decision to move slowly, so
                that our small staff could oversee construction and so that
                demand for Seaside property would almost always exceed supply.
                Even when demand seemed to disappear, as it did in the
                mid-eighties with the collapse of the Texas- Louisiana oil boom
                or in the early nineties with the destruction of the savings and
                loan industry, Jacky and Donna could spur sales by announcing a
                price increase. Prospective purchasers sitting on the fence were
                often persuaded that they should sign contracts, since waiting
                to buy would be more expensive.
              </p>
              <p>
                Going slowly also suited my own rather deliberate pace. As a
                Southerner, I tend to stroll rather slowly. Daryl would say I
                dawdle. I also like to let decisions gestate, until I have
                digested information, considered alternatives and thought about
                the likely outcomes of the alternatives. And I prefer to keep my
                options open as long as possible. My pace can be frustrating,
                even infuriating, for those who work with me, and I might have
                succumbed to paralysis in the early years, after barely getting
                out of my last two Miami projects. But Daryl&apos;s
                Master&apos;s degree was in community counseling and child
                psychology, which made her eminently qualified to help a
                child-like adult undertake the often-daunting task of community
                building. It was Daryl&apos;s advice, in 1981, to stop trying to
                design the perfect vernacular beach cottage for Northwest
                Florida and build one or two that seemed pretty good. Just as
                one <q>practiced</q> architecture, one could <q>practice</q>{' '}
                development, refining and improving over time.
              </p>
              <p>
                Going slowly and learning from our mistakes meant that the
                mistakes would never be so large that Seaside would be in danger
                of going under. There are other projects near Seaside that were
                underway 30 years ago; only Seaside still has its original
                developer. Going slowly did not insure just survival, but also
                prosperity. Town building, as opposed to conventional sub-urban
                development, benefits from critical mass, from more people, more
                houses, more shops and restaurants, more concerts, plays and
                exhibitions and from more life. As the benefits of critical mass
                began to be felt, we still owned a lot of property in Seaside,
                much of it on the beach, since we had reserved sites for two
                beachfront hotels. A proposal in the late nineties from a
                would-be hotel developer led us to hire consultants who
                persuaded us that the beachfront parcels would be difficult
                places for the operations of a hotel, and that operating income
                from a hotel would not justify the kind of land prices that
                Seaside could get from sales of single family lots. So our
                unrealistic plans had caused us to hold our most valuable
                property, much of which was sold during the real estate boom of
                the late nineties and the bubble of the early 2000s.
              </p>
            </div>
            <p>
              Now we are once again living in <q>interesting times</q>. Some of
              Seaside&apos;s homeowners seem nervous about things, and would
              sell their cottages if they could. I wish they could, and I wish
              that I could sell additional property in Seaside to someone who
              would build and manage a boutique hotel on Seaside&apos;s Central
              Square. Of course, if any Seaside property owners wish to sell at
              pre-bubble prices, there are buyers who are eager to invest their
              money in something tangible, except during the all-too-frequent
              moments of extreme nervousness about the global financial
              system&apos;s latest crisis. When I look back at our original
              ideas and our original goals, we have succeeded financially beyond
              our wildest dreams, and even now downtown Seaside&apos;s shops and
              restaurants are posting record sales. Seaside is vulnerable to
              hurricanes, but its original vision of restraint, of moving slowly
              and minimizing financial risk, positions it well to survive
              another era of <q>interesting times</q>.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.red.childImageSharp]}
              />
              The Red House, c. 1982
            </div>
            <div>
              <p>
                The value of Seaside&apos;s real estate was not immune to the
                bursting of the bubble, but it held up better than the property
                in other projects built by corporations that sold a lot of lots
                each year to maximize short-term returns to their investors. (A
                recent article in the Financial Times attests to Seaside&apos;s
                good fortune.) Wall Street is a culture that seems to value only
                the short term. Town building as practiced in Seaside would not
                be rewarding for a publicly traded company. But even though
                Seaside&apos;s values have held up reasonably well, considering
                the heights they reached at the peak of the bubble, nervous
                homeowners have found many reasons to be nervous. Chief among
                them is the perception that Seaside has become more popular,
                more crowded and less <q>exclusive</q>. There is a generally
                prevailing sentiment in our culture that equates prestige and
                desirability with exclusivity. Seaside has tried, from its
                inception, to demonstrate that this need not be the case.
                Certainly the cities and towns we love the most and most want to
                visit, New York, San Francisco, Rome, Florence and Paris among
                the cities, and Forte dei Marmi, Santa Margherita and Portofino
                among the holiday towns, are crowded with people from many
                strata of society, and it is the crowdedness that makes them the
                lively, interesting places that we love to visit. All of them
                have parking problems.
              </p>
              <p>
                Parking is a sign of success. It is a problem that can and
                should be managed. Managing it well through pricing can reduce
                the numbers of drivers wasting gas looking for parking and can
                reduce the sense that a place is too crowded. San Francisco, for
                example, has very little traffic, except near the freeways,
                because most people will use transit or will walk to avoid
                paying handsomely (some would say extortionately) for parking.
                The success of Seaside&apos;s shops and restaurants would
                suggest that the Arts and Entertainment fees do not need to be
                spent on free concerts to bring 1000 people to town during
                Spring Break, but should be spent on events that could persuade
                people to stay in Seaside for several days during the fall or
                winter when the town is sparsely populated.
              </p>
            </div>
            <p>
              I hope that Seaside will remain open and accessible to all. It is
              a holiday town, and some critics have suggested that it is not
              <q>real</q> because so few families live in Seaside year round.
              But because it is a holiday town, many thousands have experienced
              its small town urbanity and have learned from it. Some have taken
              the time, through Seaside Institute seminars and symposia, to
              learn in depth the philosophy and techniques that have formed the
              place. Others have simply learned how pleasant it is to park the
              car and join their fellow citizens as pedestrians, instead of
              competing with them for scarce asphalt during the daily commute.
              The Seaside Institute is planning to extend its seminars and
              symposia to include university students in architecture, planning,
              development, environmental studies and related fields, in order to
              connect with the next generation and give them an opportunity to
              learn from Seaside and the other nearby towns that have
              incorporated into their planning the principles of the New
              Urbanism (a movement whose birthplace is Seaside.)
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.ariel2.childImageSharp]}
              />
              Aerial View of Seaside, c. 2010
              <br />
              photo credit: Alex McLean
            </div>
            <p>
              I am happy to be solvent in these uncertain times, but I regret in
              some ways that big houses instead of grand hotels line the beach
              near Seaside&apos;s Central Square, and I still hold out hope that
              smaller, boutique hotels, will be in Seaside&apos;s future.
              Perhaps the big houses will become rooming houses or
              bed-and-breakfast inns in the future, as Seaside ages and weathers
              and as its houses are inherited by our generation&apos;s
              grandchildren. Richard Sexton, in Parallel Utopias, speculated
              that, as Seaside&apos;s cottages weathered, as roofs rusted and
              paint peeled, future generations of owners -artists and teachers
              instead of lawyers and doctors- would welcome the weathering as
              patina and as evidence of graceful aging.
            </p>
            <p>
              Aging with Grace will be Seaside&apos;s next social experiment.
              The town we have nurtured for 20 years is a NORC (a naturally
              occurring retirement community). With a bit of adjustment and a
              small staff, Seaside could provide the assistance we will need to
              age with grace without the necessity to move into an{' '}
              <q>assisted living facility</q>. Someone to drive us when we must
              give up driving ourselves and someone to help us with our
              medicines and to check on us will be essential. We would also like
              to have help with exercise and diet to help us feel younger longer
              and with meditation and spiritual guidance to help us grow wiser,
              not just older. Most of all, we will have the opportunity of
              teaching and learning from members of the next generation, in a
              place where we can all share stories, and be in touch with
              ourselves and the people we care for.
            </p>
            <div className={styles.visionImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.cinderella.childImageSharp]}
              />
              Cinderella Circle
              <br />
              photo credit: Steven Brooke
            </div>
            <div>
              <p>
                Bud, Seaside&apos;s Founding Dog, spent most of his life going
                to work with me, inspecting construction sites and chasing sand
                crabs. In his last years, his life was similar to Big
                Papa&apos;s last years. He held court in Fabs, in Ladies
                Lingerie, cared for by Dana, who had cared for Micah in his
                childhood, and doted over by hundreds of women and girls. He
                died at home, in my arms, at age twenty, 140 dog years.
              </p>

              <p>
                I hope to spend my last years with Daryl, and I hope to live
                into my late eighties or nineties, as did JS and Big Papa. If I
                am privileged to follow in their footsteps, I know that spending
                time in Seaside, sharing stories with my cronies and also with
                members of the next generations, will help me feel younger
                longer.
              </p>
            </div>
        </section>
        <hr className={styles.footnoteHr} />
        <section>

          <ol>
            <li>
              He was able to laugh at himself toward the end of his life; he
              would often tell the story of how shrewd he thought he was at the
              time, to forego the opportunity to own half of Seaside for $4,000.
              <a id='fn:1' href='#fnref:1'>
                ↩
              </a>
            </li>
            <li>
              Reminders of J.S.&apos;s vision for Seaside are found in Camp Smolian,
              a celebration of his original idea and Smolian Circle, the street
              which encircles the Lyceum, the center for education and the arts
              which J.S. and Bob envisioned for the parcel given to the
              University. <q>Dreamland Heights</q>, the downtown building designed by
              Steven Holl, was also the name J.S. gave to a small subdivision he
              planned next to Seagrove.
              <a id='fn:2' href='#fnref:2'>
                ↩
              </a>
            </li>
          </ol>
      </section>
    </Layout>
  )
}

export default Visions
